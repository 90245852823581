import React from 'react'
import { ThemeProvider, CSSReset } from '@chakra-ui/core'
import Theme from 'theme'
import SEO from './Seo'

import Footer from 'modules/Footer'

type Props = {
    children: React.ReactNode
}

const Layout = ({ children }: Props) => (
    <div>
        <SEO />
        <ThemeProvider theme={Theme}>
            <CSSReset />
            {children}
            <Footer />
        </ThemeProvider>
    </div>
)

export default Layout
