/* eslint-disable react/require-default-props */
import React from 'react'
import { Helmet } from 'react-helmet'

interface SeoProps {
    title?: string
    description?: string
    image?: string
    url?: string
}

const Seo = ({
    title = 'The Space Co | Retail, Venues & Pop-up Shops',
    description = 'Find and create amazing spaces to tell your story.',
    image = 'url',
    url = 'https://thespaceco.net'
}: SeoProps) => (
    <Helmet>
        {/* General tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta charSet="utf-8" />
        {/* OpenGraph tags */}
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={title} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
            href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap"
            rel="stylesheet"
        />
    </Helmet>
)

export default Seo
