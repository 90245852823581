import React from 'react'
import { css } from '@emotion/core'

import { Box, Text, Flex } from '@chakra-ui/core'
import { Link } from 'components'

export default () => (
    <Box p="2rem 1rem" bg="black">
        <Flex justifyContent="center" alignItems="center">
            <Box textAlign="center" mr="1rem">
                <Text fontSize="body" fontWeight="semibold" fontFamily="body" color="white">
                    The Space Co
                </Text>
            </Box>
            <Box
                textAlign="center"
                position="relative"
                ml="1rem"
                css={css`
                    ::before {
                        content: '';
                        position: absolute;
                        left: -1rem;
                        background: #fff;
                        height: 20px;
                        width: 2px;
                        top: 4px;
                    }
                `}
            >
                <Text fontSize="body" fontWeight="400" fontFamily="body" color="white">
                    <Link color="white" fontWeight="normal" to="mailto:takeoff@thespaceco.net">
                        {' '}
                        Contact Us
                    </Link>
                </Text>
            </Box>
        </Flex>
    </Box>
)
